export default {
  free: {
    id: 'free',
    title: 'free',
    limits: {
      polls: 2,
      questions: 5,
    },
    price: {
      month: 0,
      year: 0,
      monthYearly: 0,
    },
    features: {
      onlineSupport: true,
      ai: true,
    },
    branding: {
      defaultThemes: true,
      themeEdit: false,
      logo: false,
    },
  },
  basic: {
    hidden: true,
    id: 'basic',
    replacedBy: 'basic_2025',
    title: 'basic',
    subscriptionId: '94b90ecc3ed3a11',
    subscriptionUrl: 'https://unislide.ainox.pro/94b90ecc3ed3a11',
    price: {
      month: 49000,
    },
    features: {
      exportToXls: true,
      imagesLibrary: true,
      onlineSupport: true,
      ai: true,
    },
    branding: {
      defaultThemes: true,
      themeEdit: false,
      logo: false,
    },
  },
  basic_2025: {
    id: 'basic_2025',
    title: 'basic',
    subscriptionId: '94b90ecc3ed3a11',
    subscriptionUrl: 'https://unislide.ainox.pro/94b90ecc3ed3a11',
    price: {
      month: 99000,
      year: 828000,
      monthYearly: 69000,
    },
    features: {
      exportToXls: true,
      imagesLibrary: true,
      onlineSupport: true,
      ai: true,
    },
    branding: {
      defaultThemes: true,
      themeEdit: false,
      logo: false,
    },
  },
  pro: {
    hidden: true,
    id: 'pro',
    replacedBy: 'pro_2025',
    title: 'pro',
    subscriptionId: 'f6deb41086443eb',
    subscriptionUrl: 'https://unislide.ainox.pro/f6deb41086443eb',
    price: {
      month: 99000,
    },
    features: {
      branding: true,
      exportToXls: true,
      onlineSupport: true,
      prioritySupport: false,
      imagesLibrary: true,
      userFields: true,
      ai: true,
    },
    branding: {
      defaultThemes: true,
      themeEdit: true,
      logo: true,
    },
    other: {
      payByBill: true,
    },
  },
  pro_2025: {
    id: 'pro_2025',
    title: 'pro',
    subscriptionId: 'f6deb41086443eb',
    subscriptionUrl: 'https://unislide.ainox.pro/f6deb41086443eb',
    price: {
      month: 199000,
      year: 1668000,
      monthYearly: 139000,
    },
    features: {
      branding: true,
      exportToXls: true,
      onlineSupport: true,
      prioritySupport: false,
      imagesLibrary: true,
      userFields: true,
      ai: true,
    },
    branding: {
      defaultThemes: true,
      themeEdit: true,
      logo: false,
    },
    other: {
      payByBill: true,
    },
  },
  business: {
    id: 'business',
    title: 'business',
    price: {
      month: 499000,
      year: 4188000,
      monthYearly: 349000,
    },
    // request: true,
    features: {
      branding: true,
      exportToXls: true,
      onlineSupport: true,
      prioritySupport: true,
      imagesLibrary: true,
      userFields: true,
      ai: true,
    },
    branding: {
      defaultThemes: true,
      themeEdit: true,
      logo: true,
    },
    other: {
      payByBill: true,
    },
  },
}

export const features = {}
